import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import HandymanIcon from "@mui/icons-material/Handyman";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AboutICon from '@mui/icons-material/AccountBox';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from "@mui/icons-material/X"
import BlogIcon from "@mui/icons-material/ArticleOutlined";
const sectionsData = {

  sections: [
    {
      title: "Home",
      url: "/",
      icon: () => { return (<HomeIcon />) },
    },
    {
      title: "About Me",
      url: "/about",
      icon: () => { return (<AboutICon />) },
      subSectons: [
        {
          title: "Preface",
          url: "/about",
          icon: () => { return (<AboutICon />) },
        },
        {
          title: "Experience",
          url: "/about#experience",
          icon: () => { return (<BusinessIcon />) },
        },
        {
          title: "Skills",
          url: "/about#skills",
          icon: () => { return (<HandymanIcon />) },
        },
        {
          title: "Certifications",
          url: "/about#certifications",
          icon: () => { return (<SchoolIcon />) },
        },
        {
          title: "Projects",
          url: "/projects",
          icon: () => { return (<WorkIcon />) },
        },
        {
          title: "Badges",
          url: "https://www.credly.com/users/hossam-katory/badges",
          icon: () => { return (<MilitaryTechIcon />) },
          external: true,
        },

      ],

    },
    {
      title: "My Blog",
      url: "https://blog.katory.net/",
      icon: () => { return (<BlogIcon />) },
      external: true,
    },
    {
      title: "LinkedIn",
      url: "https://www.linkedin.com/in/hossamkatory",
      icon: () => { return (<LinkedInIcon />) },
      external: true,
    },
    {
      title: "X",
      url: "https://www.x.com/hossamkatory",
      icon: () => { return (<XIcon />) },
      external: true,
    },
    {
      title: "Youtube",
      url: "https://www.youtube.com/@HKITCS",
      icon: () => { return (<YouTubeIcon />) },
      external: true,
    },

  ],
};

export default sectionsData;