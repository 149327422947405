// App.js
import React from 'react';
import AppSidebar from './components/AppSidebar';
import Home from './components/Home';
import About from './components/About'
import './components/AppSidebar.css';
import './App.css';
import { Theme } from './components/Themes'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Projects from './components/Projects'
import AdsComponent from './components/AdsComponent';


const App = () => {

  var stheme = sessionStorage.getItem('theme');
  const [theme, setTheme] = React.useState(stheme ? stheme.toString() : Theme.DARK);
  document.body.className = stheme ? stheme.toString() : Theme.DARK;

  const handleThemeChange = (e) => {
    sessionStorage.setItem('theme', theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
    setTheme(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
    document.body.className = stheme.toString();
  };
  let styles = "App container-fluid flex-container wrapper " + theme;
  return (

    <div id="outer-container" className={styles} >

      <AppSidebar theme={theme} changeHandler={handleThemeChange} pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />

      <main id="content" className="flex-item-right content">
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/projects' element={<Projects />} />
          </Routes>
        </Router>
      </main>
      <AdsComponent dataAdSlot='X7XXXXXX5X' />
    </div>
  );
};

export default App;
